//flexbox
@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@mixin flex-basis-auto {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

@mixin flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@mixin flex-wrap-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin flex-wrap-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@mixin flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
@mixin flex-direction-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@mixin flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin flex-order($num) {
  -webkit-box-ordinal-group: $num;
  -ms-flex-order: $num;
  order: $num;
}

@mixin flex-justify-flex-start {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@mixin flex-justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}
@mixin flex-justify-flex-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@mixin flex-justify-space-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@mixin flex-justify-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@mixin align-items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@mixin flex-align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
@mixin flex-align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}
@mixin flex-align-items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
