// svg size settings
@mixin svgSize($svg_size_w, $svg_size_h) {
  width: $svg_size_w + px;
  height: $svg_size_h + px;
}


.icon-first {
  @include flexbox;
  @include flex-justify-flex-start;
  @include align-items-center;

  &:before {
    display: inline-block;
    width: 20px;
    height: 26px;
    content: "";
    margin-right: 6px;
    background-image: url("../images/front/icon_first.png");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }
}


.icon-tri3 {
  @include flexbox;
  @include flex-justify-flex-start;
  @include align-items-center;

  &:before {
    display: inline-block;
    width: 36px;
    height: 11px;
    content: "";
    margin-right: 15px;
    background-image: url("../images/common/icon_tri3_gr.png");
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }
}


/*
@mixin icon-spot($status) {
  @if $status == 'maplink' {
    fill: color-wh(1);
    @include svgSize (12,16);
  } @else if $status == '' {
    
  }
}
*/


/*
// 外部リンクicon svg
@mixin icon-external() {
  fill: color-effect01(1);
  @include svgSize (14,14);
}
.externalIcon, %externalIcon {
  padding: 0 5px;
  @include icon-external();
}
*/
