.p1-logo {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include align-items-center;
  position: relative;
  z-index: 1200;
  width: 150px;
  height: 100%;
  box-sizing: border-box;

  @include breakpoint-sp {
  }
  @include breakpoint-pc {
    width: 180px;
    margin-right: 10px;
  }
  @include breakpoint-content {
    width: 200px;
    margin-right: 30px;
  }

  &_item {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    width: 100%;
    height: auto;
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
    margin-right: 12px;

    @include breakpoint-sp {
      width: 100%;
      margin-right: 14px;
    }
    @include breakpoint-pc {
      width: 100%;
//      margin-right: 10px;
    }

    &.-full {
      display: none;

      @include breakpoint-content {
        display: block;
      }
    }
    
    &.-slim {
      display: block;

      @include breakpoint-content {
        display: none;
      }
    }
  }

  &_txt {
    display: block;
    width: 182px;  //203px * 0.9
    height: auto;

    @include breakpoint-sp {
      width: 203px;  //203px
    }
    @include breakpoint-pc {
      width: 224px;  //249px * 0.9
    }
    @media all and (min-width: 900px) {
      width: 249px;  //277px * 0.9
    }
    @include breakpoint-content {
      width: 277px;  //277px
    }
    @media all and (min-width: 1060px) {
      width: 312px;  //347px * 0.9
    }
    @include breakpoint-mainbox {
      width: 347px;
    }

    &.-full {
      display: none;

      @include breakpoint-content {
        display: block;
      }
    }
    
    &.-slim {
      display: block;

      @include breakpoint-content {
        display: none;
      }
    }
  }
}