.p2-ftinfo {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include align-items-center;
  width: 100%;
  @include font-zomin("r");
  font-size: 1.1rem;
  @include lineHeight(1.7, 1.7);

  @include breakpoint-pc {
    @include flex-direction-row;
    font-size: 1.3rem;
  }
  @include breakpoint-content {
    @include flex-wrap-nowrap;
    font-size: 1.4rem;
  }

  &_item {
    box-sizing: border-box;

    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }

    &:last-child {
    }

    &.-item1 {
      margin-bottom: 15px;

      @include breakpoint-pc {
        margin-bottom: 0;
        margin-right: 40px;
      }
      @media all and (min-width: 900px) {
        margin-right: 60px;
      }
      @include breakpoint-content {
        margin-right: auto;
      }
    }

    &.-item2 {
      margin-bottom: 15px;

      @include breakpoint-pc {
        @include flex-shrink-0;
        width: 395px;
        margin-bottom: 0;
      }
      @include breakpoint-content {
        width: 440px;
      }
      @include breakpoint-mainbox {
        width: 480px;
      }
    }

    &.-item3 {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-start;

      @include breakpoint-pc {
        @include flex-shrink-0;
        width: 100%;
        margin-top: 30px;
      }
      @include breakpoint-content {
        @include flex-justify-space-between;
        width: 100px;
        margin-top: 0;
      }
    }
  }
  
  &_logo {
    width: 270px; //338px * 0.8
    
    @include breakpoint-pc {
      width: 270px; //338px * 0.8
    }
    @media all and (min-width: 900px) {
      width: 304px; //338px * 0.9
    }
    @include breakpoint-content {
    }
    @include breakpoint-mainbox {
      width: 338px;
    }
  }
  
  &_bh {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include align-items-start;

    @include breakpoint-sp {
      @include flex-direction-row;
    }

    &_head {
    }

    &_body {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-start;
      }

    &_zone {
      box-sizing: border-box;
      padding-right: 5px;

      @include breakpoint-pc {
        padding-right: 8px;
      }
      @include breakpoint-content {
        padding-right: 8px;
      }
    }

    &_wd {
      box-sizing: border-box;
      padding-right: 10px;

      @include breakpoint-tb {
        padding-right: 15px;
      }
      @include breakpoint-pc {
        padding-right: 15px;
      }
      @include breakpoint-content {
        padding-right: 25px;
      }
    }

    &_hour {
      
    }
  }
  
  &_sns {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 10px;
//    background-color: color-wh(1);
//    border-radius: 50%;

    @include breakpoint-content {
      margin: 0;
    }
  }
  
  &_txt {
  }

  &_sup {
    font-size: 1rem;

    @include breakpoint-pc {
      font-size: 1.1rem;
    }
    @include breakpoint-content {
      font-size: 1.2rem;
    }
  }

}