.c-flex {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-stretch;

  &_item {
    &.-box2 {
      width: 100%;

      @include breakpoint-pc {
        width: 47.5%;
      }
    }
/*
    &.-box3 {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @media all and (min-width:520px) {
        width: 47.5%;
        margin-bottom: 30px;
      }
      @media all and (min-width:740px) {
        width: 31%;
        margin-bottom: 0;
      }
      @media all and (min-width:920px) {
        width: 29.3%;
      }
    }
    
    &.-dummy {
      display: none;
      opacity: 0;
      height: 1px;
 
      @media all and (min-width:520px) {
        display: block;
        width: 1%;
      }
    }
*/
  }

/*
  &.-odd {
    @media all and (min-width:520px) and (max-width:739px) {
      @include flex-justify-center;
    }
    & .c-flex_item {
      &:nth-child(odd) {
        @media all and (min-width:520px) and (max-width:739px) {
          margin-right: 5%;
        }
      }
      &:last-child {
        @media all and (min-width:520px) and (max-width:739px) {
          margin: 0;
        }
      }
    }
  }
*/

/*
  &.-post {
    & .c-flex_item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      @media all and (min-width:740px) {
        margin-bottom: 40px;
      }
    }
  }
*/

}

/*
.c-flex02 {
  @include flexbox;
  @include flex-direction-column;
  @include flex-wrap-wrap;

  @include breakpoint-maxhalf {
    @include flex-direction-row;
    @include flex-wrap-nowrap;
    @include flex-justify-space-between;
  }
  &_item {
    &.-item01 {
      width: 60vw;
      max-width: 500px;
      margin: 0 auto;
      order: 2;
      display: none;
      @include breakpoint-maxhalf {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
        padding: 0 60px 0 0;
      }
    }
    &.-item02 {
      width: 100%;
      order: 1;
      margin-bottom: 6vw;
      @include breakpoint-maxhalf {
        width: 60%;
        order: 2;
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
      }
    }
  }
}
*/