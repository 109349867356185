.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-largeSpace {
    padding: 45px 0;

    @include breakpoint-pc {
      padding: 90px 0;
    }

    &.-pb0 {
      padding: 45px 0 0;

      @include breakpoint-pc {
        padding: 90px 0 0;
      }
    }

    &.-pb50 {
      padding: 45px 0 25px;

      @include breakpoint-pc {
        padding: 90px 0 50px;
      }
    }
  }

  &.-midSpace {
    padding: 40px 0;

    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;

      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }


  ///// セクション全体の背景色を変える /////
  &.-bg01 {
    color: color-black01(1);
    background-color: color-wh(1);
  }


  &_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    @include breakpoint-mainbox {
      width: 1000px;
    }

    &.-visible {
      overflow: visible;
    }
    
    &.-full {
      @include breakpoint-mainbox {
        width: $max;
      }
    }
    
    &_inner {
      &.-narrow {
        @include breakpoint-content {
          width: 100%;
          padding-left: 11.5%;
          padding-right: 11.5%;
          box-sizing: border-box;
        }
        
        &.-leftspace {
          @include breakpoint-content {
            padding-right: 0;
          }
        }
        
        &.-rightspace {
          @include breakpoint-content {
            padding-left: 0;
          }
        }
      }
    }
  }

}
