body {
  color: color-wh(1);
  background: color-black02(1);
  width: 100%;
  @include fontSize(1.8, 1.5);
  line-height: 1;
  @include font-ntsan("r");

  @include breakpoint-pc {
  }
}

a {
  color: color-wh(1);
  text-decoration: none;
}

::selection {
  background: color-brown01(1);
}
::-moz-selection {
  background: color-brown01(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
