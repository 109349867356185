@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Poppins:wght@600&family=Shippori+Mincho:wght@500;600&family=Zen+Old+Mincho:wght@400;700;900&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


//Myriad Pro Regular
@font-face{ 
  font-family: 'abhaya_libre_extraboldregular';
    src: url('../font/abhayalibre-extrabold-webfont.eot');
    src: url('../font/abhayalibre-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font/abhayalibre-extrabold-webfont.woff2') format('woff2'),
         url('../font/abhayalibre-extrabold-webfont.woff') format('woff'),
         url('../font/abhayalibre-extrabold-webfont.svg#abhaya_libre_extraboldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@mixin font-aleb() {
  font-family: "abhaya_libre_extraboldregular";
  font-weight: normal;
  font-style: normal;
}


//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  @if $status == 'r' {
    font-weight: 400;
  }
}


//Zen Old Mincho
@mixin font-zomin($status) {
  font-family: 'Zen Old Mincho', serif;
  font-style: normal;
  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'bd' {
    font-weight: 700;
  } @else if $status == 'bk' {
    font-weight: 900;
  }
}


//しっぽり明朝 | Shippori Mincho
@mixin font-spmin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  @if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'sb' {
    font-weight: 600;
  }
}


//Poppins
@mixin font-poppins($status) {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  @if $status == 'sb' {
    font-weight: 600;
  }
}
