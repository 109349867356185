.p0-hero {
  @include breakpoint-pc {
  }
  
  /* トップページ */
  &.-front {
    position: relative;
    height: 100vh;
    padding: 0;
    box-sizing: border-box;

    @include breakpoint-pc {
    }
    
    & .p0-hero_bg {
      width: 100%;
      height: 100%;

      @include breakpoint-pc {
      }
      
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
      }
    }
    
    & .p0-hero_catchcopy {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      @include breakpoint-pc {
      }

      .catchcopy {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 76%; //575px / 750px
        min-width: 228px;
        height: auto;
        pointer-events: none;
  
        @include breakpoint-pc {
          transform: translate(calc(-50% + 0.9vw), calc(-50% - 1.8vw)); // 10px / 1120px, 20px / 1120px
          width: 575px;
          min-width: auto;
        }
        @include breakpoint-mainbox {
          transform: translate(calc(-50% + 10px), calc(-50% - 20px));
        }
      }
    }
    
    & .p0-hero_filter {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 250px;
      background-image: url("../images/front/kv_filter.png");
      background-position: center bottom;
      background-size: 2px 100%;
      background-repeat: repeat-x;
    }
  }

  ///// エラーページ ///// 
  &.-error {
    position: relative;
    height: 300px;
    padding: 0;
    box-sizing: border-box;

    @include breakpoint-pc {
      height: 440px;
    }

    & .p0-hero_catchcopy {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }


  &_headline {
    & img {
      display: block;
    }
    
    &.-center {
      text-align: center;
    }
  }
  
  
}