.p1-drawer {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-space-between;
  @include align-items-stretch;
  position: absolute;
  top: calc(0px - #{$drawer-space-sp});
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100vh);

  @include breakpoint-pc {
    position: relative;
    top: 0;
    height: $drawer-space-pc;
    transform: translateY(0) !important;
  }
  
  &.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn;
    background: color-wh(0.95);
  }
  
  &.is-close {
    animation: 0.3s ease-in 0s 1 both drawerOut;

    @include breakpoint-pc {
      animation: none;
      background-color: transparent;
    }
  }
  
  // ドロワーの入れ物
  &-wrap {
    position: absolute;
    top: $drawer-space-sp;
    left: 0;
    width: 100%;
    overflow: hidden;

    @include breakpoint-pc {
      position: relative;
      top: 0;
      height: $drawer-space-pc;
    }

    &.is-open {
      height: calc(100vh - #{$drawer-space-sp});

      @include breakpoint-pc {
        height: calc(100vh - #{$drawer-space-pc});
      }
    }
    
    &.is-close {  
      animation: 0.3s ease-in 0s 1 both drawerWrapOut;
    }
  }

  // ハンバーガーアイコンの設置スペース
  &-open {
    @include flexbox;
    @include flex-justify-center;
    @include align-items-center;
    position: absolute;
    top: 10px;
    right: 5vw;
    z-index: 1200;/* 重なり順を一番上に */
    height: 40px;
    width: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
//    background-color: color-black01(1);

    @include breakpoint-pc {
      display: none;
    }

    /* ハンバーガーメニューのアイコン */
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      height: 3px;
      width: 25px;
      content: '';
      border-radius: 3px;
      background-color: color-black01(1);
      transition: 0.5s;
    }

    & span {
      &::before {
        bottom: 8px;
        transform-origin: top left;
      }
      &::after {
        top: 8px;
        transform-origin: bottom left;
      }
    }
    
    //オープン時(×ボタン)
    &.is-open {
      span {
        background-color: color-black01(0);
      }
      
      span::before {
        transform: translate(5px, 0) rotate(45deg);
      }
      span::after {
        transform: translate(5px, 1px) rotate(-45deg);
      }
    }
  }
}

