.p3-headline {
  text-align: center;
  color: color-wh(1);
  
  &.-bk01 {
    color: color-black01(1);
  }

  &.-bk02 {
    color: color-black02(1);
  }
  
  //斜体画像見出し
  //見出しテキストは画像として書き出す。画像の横幅は550px(倍サイズで書き出すので、実際は1100px)に統一する。
  &.-exmouth {
    margin-bottom: 30px;
    text-align: left;

    @include breakpoint-content {
      margin-bottom: 60px;
    }

    img {
      width: 220px;
      height: auto;

      @include breakpoint-sp {
      }
      @include breakpoint-contenthalf {
        width: 330px; //550px * 0.6
      }
      @media all and (min-width: 700px) {
        width: 440px; //550px * 0.8
      }
      @include breakpoint-pc {
      }
      @include breakpoint-content {
        width: 550px;
      }
    }
  }
  
  // インスタグラム
  &.-ttl01 {
    position: relative;
    @include font-poppins("sb");
    @include fontSize(3.8, 3);
    text-align: justify;
    
    &.-center {
      text-align: center;
    }

    &.-ls01 {
      letter-spacing: 0.1em;
    }
  }
  
  // メニュー名
  &.-ttl02 {
    position: relative;
    @include font-zomin("bk");
    @include fontSize(3.8, 2.4);
    line-height: 1.3;
    text-align: left;

    &.-center {
      text-align: center;
    }

    &.-ls01 {
      letter-spacing: 0.1em;
    }
  }
  
  // アイコン有メニュー名
  &.-menuname {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
  }
}