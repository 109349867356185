.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include breakpoint-pc {
    border-radius: 15px;
  }
  @include breakpoint-max {
//    width: 1120px;
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 5%;
    box-sizing: border-box;

    @include breakpoint-pc {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include align-items-stretch;
      position: relative;
      transform: translateY(0);
      width: 100%;
      height: 100%;
      padding: 0;
      background-image: none;
      background-color: transparent;
    }
  }

  &_list {
    width: 100%;
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    padding-top: 60px;
    box-sizing: border-box;

    @include breakpoint-sp {
      padding-top: 75px;
    }
    @include breakpoint-pc {
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      @include flex-align-content-center;
//      width: 270px;
//      max-width: 320px;
      height: 100%;
//      margin-left: 10px;
//      margin-right: 10px;
      padding: 0;
    }
    @media all and (min-width: 900px) {
//      width: 32vw;
//      margin-left: 20px;
//      margin-right: 20px;
    }
    @include breakpoint-content {
//      width: 320px;
//      margin-left: 25px;
//      margin-right: 25px;
    }
  }

  &_item {
    display: block;
    position: relative;
    padding: 0 3px;
    box-sizing: border-box;
//    transition: all linear;

    @include breakpoint-pc {
      height: 32.5%;
      padding: 0 3px;
    }
    @include breakpoint-content {
      padding: 0 5px;
    }
    
    &:last-of-type {
      border-bottom-style: none;
    }
    
    &.-tel {
      @include breakpoint-pc {
        margin-left: auto;
      }
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 1.8rem;
    color: color-black01(1);
    text-align: left;
//    transition: all linear;
    line-height: 1;
    @include font-poppins("sb");

    @include breakpoint-sp {
      padding: 15px 0;
    }
    @include breakpoint-pc {
      @include flex-justify-center;
      padding: 0 4px;
      font-size: 1.8rem;
      text-align: center;
    }
    @include breakpoint-content {
      padding: 0 5px;
      font-size: 2rem;
    }
    
    &.-tel {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      @include flex-align-content-center;
    }
  }
  
  &_txt {
    display: inline-block;
    box-sizing: border-box;

    &.-jp {
      padding-bottom: 2px;
      padding-right: 5px;
      @include font-spmin("sb");
      font-size: 2rem;

      @include breakpoint-pc {
        padding-right: 5px;
        font-size: 2rem;
      }
      @include breakpoint-content {
        padding-right: 9px;
        font-size: 2.2rem;
      }
    }
    
    &.-num {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      @include flex-align-content-center;
      font-size: 2.4rem;

      @include breakpoint-pc {
        font-size: 2.4rem;
      }
      @include breakpoint-content {
        font-size: 2.7rem;
      }

      &:before {
        display: inline-block;
        width: 28px;
        height: 28px;
        content: "";
        margin-right: 6px;
        background-image: url("../images/common/icon/tel.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        @include breakpoint-pc {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
        @include breakpoint-content {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
      }
    }
  }
}
