.p1-fixRightTxt {
  display: none;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 999;
  transform: translateY(-50%);
  width: 100%;
  pointer-events: none;

  @include breakpoint-pc {
    display: block;
    right: -3.5%;
  }
  @include breakpoint-content {
    right: 0;
  }
  
  &_inner {
    @include flexbox;
    width: 90%;
    margin: 0 auto;

    @include breakpoint-mainbox {
      width: 1000px;
    }
  }

  &_item {
    margin-left: auto;
    margin-right: 0;
    @include font-aleb();
    @include fontSize(2.4, 2);
    @include tategaki();
    letter-spacing: 0.1em;
    color: color-brown01(1);
  }
  
  // マップより下までスクロールした際は、非表示にする
  &.js-fadeout {
    animation: fadeout-keyframes 0.15s ease-in-out 0s 1 forwards;
  }
  &.js-fadein {
    animation: fadein-keyframes 0.3s ease-in-out 0s 1 forwards;
  }
  &.js-inithide {
    opacity: 0;
  }
}