.l-header {
  width: 100%;
  height: $drawer-space-sp;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0;

  @include breakpoint-pc {
    top: 25px;
    width: 100%;
    height: $drawer-space-pc;
    padding: 0 5%;
    box-sizing: border-box;
    background-image: none;
  }
  @include breakpoint-content {
    padding: 0 30px;
  }
  @include breakpoint-mainbox {
    padding: 0 30px;
  }
  
  &.js-isscroll {
    position: fixed;
  }
  &.js-noscroll {
    position: absolute;
  }
  
  //ドロワーオープン時
  &.is-open {
    .l-header_inner {
      background-color: color-wh(0.95);
    }
  }
  
  //ドロワークローズ時
  &.is-close {
    .l-header_inner {
      animation: 0.3s ease-in 0s 1 both drawerOutHeaderBgc;

      @include breakpoint-pc {
        animation: none;
        background-color: color-wh(0.65);
      }
    }
  }

  &_inner {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    width: 100%;
    height: 100%;
    max-width: 1500px;  //ヘッダーグロナビの中身の最大横幅
    margin: 0 auto;
    padding: 0 5vw;
    box-sizing: border-box;
    background-color: color-wh(0.65);

    @include breakpoint-pc {
      padding: 0 15px 0 25px;
      border-radius: 6px;
    }
    @include breakpoint-content {
      padding: 0 60px 0 50px;
    }

  }
}

