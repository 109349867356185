.p2-map {
  overflow: hidden;
  width: 100%;
  height: 400px;
  
  @include breakpoint-pc {
    height: 400px;
  }

//左上の.place-cardを非表示にしたい場合は、以下のコメントを外して有効にする
/*
  iframe {
    width: 100%;
    height: 760px;
    margin-top: -180px;
  }
*/
}
