@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}

@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-black01($opacity) {
  @return rgba(26, 26, 26, $opacity);  //#1a1a1a、黒1、メニューテキスト色など
}
@function color-black02($opacity) {
  @return rgba(34, 34, 33, $opacity);  //#222221、黒2、黒背景色など
}

@function color-brown01($opacity) {
  @return rgba(198, 156, 109, $opacity);  //#c69c6d、薄茶色、縦書きタイトル色など
}
