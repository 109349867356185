.p2-ftnav {
  padding: 20px 0 0;
  border-top: 1px solid color-wh(1);
  box-sizing: border-box;

  &_logo {
    text-align: center;

    a {
      display: inline-block;
    }

    img {
      width: 150px;
      height: auto;

      @include breakpoint-pc {
        width: 183px;
      }
    }
  }
}