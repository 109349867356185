// fadein effect
/* 画面外にいる状態 */
.js-fadeIn {
  opacity : 0.1;
  transform : translate(0, 30px);
  transition : all 1000ms;
}
 
/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity : 1;
  transform : translate(0, 0);
}


//// ドロワー /////
@keyframes drawerWrapOut {
  0% {
    height: calc(100vh - #{$drawer-space-sp});

    @include breakpoint-pc {
      height: calc(100vh - #{$drawer-space-pc});
    }
  }
  99% {
    height: calc(100vh - #{$drawer-space-sp});

    @include breakpoint-pc {
      height: calc(100vh - #{$drawer-space-pc});
    }
  }
  100% {
    height: auto;
  }
}

@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: color-wh(0);
  }
  1% {
    background: color-wh(0.95);
  }
  100% {
    background: color-wh(0.95);
    transform: translateY(0);
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-wh(0.95);
  }
  99% {
    background: color-wh(0.95);
  }
  100% {
    background: color-wh(0);
    transform: translateY(-100vh);
  }
}

@keyframes drawerOutHeaderBgc {
  0% {
    background: color-wh(0.95);
  }
  99% {
    background: color-wh(0.95);
  }
  100% {
    background: color-wh(0.65);
  }
}
