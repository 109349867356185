@mixin btnsize($pc, $sp) {
  width: 100%;
  @include breakpoint-sp {
    max-width: $sp;
  }
  @include breakpoint-pc {
    width: $pc;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;

  &_inner, a {
    display: block;
    width: 100%;
    padding: 11px 0 12px;
    box-sizing: border-box;
    @include font-ntsan("m");
    color: color-wh(1);
    line-height: 1;
    @extend %hover;
//    cursor: pointer;

    @include breakpoint-pc {
    }
  }

  @if $status == 1 {
    &_inner, a {
      @include font-ntsan("r");
      font-size: 1.3rem;
      color: color-black01(1);
      @include breakpoint-pc {
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }

  @else if $status == 2 {
    &_inner, a {
      padding: 15px 0 15px;
      @include font-ntsan("r");
      font-size: 1.5rem;
      color: color-black01(1);
      @include breakpoint-pc {
        padding: 17px 0 19px;
        font-size: 2rem;
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }

  @else if $status == 3 {
    &_inner, a {
      padding: 14px 0 15px;
      @include font-ntsan("r");
      font-size: 1.5rem;
      color: color-black01(1);
      @include breakpoint-pc {
        padding: 12px 0 12px;
        font-size: 1.6rem;
      }
      
      &.-en {
        @include font-ntsan("r");
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }

  @else if $status == 4 {
    &_inner, a {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      padding: 10px 0;
      border-bottom: 1px solid color-black01(1);
      @include font-ntsan("r");
      font-size: 1.3rem;
      color: color-black01(1);

      @include breakpoint-pc {
        padding: 10px 0;
        font-size: 1.4rem;
      }
      
      &:before {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 13px;
        content: "";
        margin-right: 10px;
        background-image: url("../images/common/icon_download.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        @extend %hover;
  
        @include breakpoint-sp {
          margin-right: 13px;
        }
        @include breakpoint-pc {
          width: 18px;
          height: 17px;
        }
      }
    }

    &_inner {
      a {
        padding: 0;
      }
    }
  }
}


.p0-btn1 {
  position: relative;
  width: 150px;
  @include btn(1);
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: color-brown01(1);

  @include breakpoint-pc {
    width: 121px;
  }

  &.-type01 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 2px;
    }
  }

  &.-type02 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 2px;
    }
  }

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn2 {
  position: relative;
  @include btnsize(300px, 335px);
  @include btn(2);
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: color-brown01(1);

  &.-type01 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 4px;
    }
  
    &.-external {
      &:before {
        background-image: url("../images/common/icon_linkexternal_lg.png");
      }
    }
  }

  &.-type02 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 4px;
    }
  
    &.-external {
      &:before {
        background-image: url("../images/common/icon_linkexternal_yg.png");
      }
    }
  }

  &.-zi10 {
    position: relative;
    z-index: 10;
  }
  
  &.-external {
    a {
      span {
        margin-left: -0.5em;
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;

      @include breakpoint-pc {
        right: 20px;
        width: 16px;
        height: 16px;
      }
    }
  }

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn3 {
  position: relative;
  @include btnsize(300px, 275px);
  @include btn(3);
  border-radius: 100vh;
  box-sizing: border-box;
  background-color: color-brown01(1);

  &.-type01 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 2px;
    }
  
    &.-external {
      &:before {
        background-image: url("../images/common/icon_linkexternal_lg.png");
      }
    }
  }

  &.-type02 {
    border: 3px solid color-brown01(1);

    @include breakpoint-pc {
      border-width: 2px;
    }
  
    &.-external {
      &:before {
        background-image: url("../images/common/icon_linkexternal_yg.png");
      }
    }
  }

  &.-zi10 {
    position: relative;
    z-index: 10;
  }
  
  &.-external {
    a {
      span {
        margin-left: -0.5em;
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @extend %hover;

      @include breakpoint-pc {
        right: 20px;
        width: 13px;
        height: 13px;
      }
    }
  }

  p {
    margin-bottom: 0 !important;
  }
}

.p0-btn4 {
  position: relative;
  @include btnsize(200px, 200px);
  @include btn(4);
  box-sizing: border-box;

  &.-zi10 {
    position: relative;
    z-index: 10;
  }

  p {
    margin-bottom: 0 !important;
  }
}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include align-items-center;
    
    @include breakpoint-pc {
      @include align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}