.c-swiper {
  &.swiper-photos {
    overflow: visible !important;

    //等速移動
//    .swiper-wrapper {
//      transition-timing-function: linear;
//    }
  }
}

#sb_instagram {
  padding-bottom: 0 !important;
}

