.p3-text {
  ///// テキストシャドウ /////
  &.-shadow {
    &.-bk {
      -moz-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      -webkit-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      -ms-text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.58), 0px 0px 16px rgba(0, 0, 0, 0.36);
    }

    &.-wh {
      -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
    }
  }
  
  ///// 色付きテキスト /////
  &.-bk01 {
    color: color-black01(1);
  }

  &.-bk02 {
    color: color-black02(1);
  }


  ///// リード文 /////
  &.-lead01 {
    position: relative;
    @include font-spmin("sb");
    font-size: 2.8rem;
    text-align: justify;
    line-height: 1.4;

    @include breakpoint-tb {
      font-size: 3.6rem;
    }
    @include breakpoint-pc {
      font-size: 3.84rem;
    }
    @include breakpoint-content {
      font-size: 4.32rem;
    }
    @include breakpoint-mainbox {
      font-size: 4.8rem;
    }

    &.-ls009 {
      letter-spacing: 0.09em;
    }
  }

  &.-lead02 {
    position: relative;
    @include font-zomin("bd");
    @include fontSize(2.4, 1.8);
    text-align: justify;
    line-height: 1.4;

    @include breakpoint-pc {
    }

    &.-lh15 {
      @include lineHeight(1.5, 1.5);
    }

    &.-ls009 {
      letter-spacing: 0.09em;
    }
  }


  ///// テキスト /////
  &.-t01 {
    position: relative;
    @include font-spmin("m");
    @include fontSize(2, 1.5);
    text-align: justify;

    @include breakpoint-pc {
    }
    
    &.-center {
      text-align: center;
      
      &_spleft {
        text-align: justify;
        
        @include breakpoint-pc {
          text-align: center;
        }
      }
    }
    
    &.-lh25 {
      @include lineHeight(2.5, 2);
    }

    &.-ls009 {
      letter-spacing: 0.09em;
    }
  }

  &.-t02 {
    @include font-ntsan("r");
    @include fontSize(1.8, 1.3);
    text-align: justify;
    
    &.-lh13 {
      @include lineHeight(1.3, 1.3);
    }

    &.-center {
      text-align: center;
    }
  }

  &.-t03 {
    @include font-zomin("r");
    @include fontSize(1.8, 1.3);
    text-align: justify;
    
    &.-lh20 {
      @include lineHeight(2, 1.65);
    }

    &.-center {
      text-align: center;
    }
    
    &.-bold {
      @include font-zomin("bd");
    }
    
    &.-black {
      @include font-zomin("bk");
    }
  }

  &.-t04 {
    @include font-zomin("r");
    @include fontSize(1.4, 1);

    &.-center {
      text-align: center;
    }
    
    &.-bold {
      @include font-zomin("bd");
    }
    
    &.-black {
      @include font-zomin("bk");
    }
  }
}