$max: 1200px; // 最大幅 - 1200px(デザイン幅)
$mainbox: 1140px; // ヘッダー幅 - 1140px
$content: 1000px; // メインコンテンツ幅 - 1000px(1つ内側のガイド)
$pc: 800px; // PC - 800
$contentinner: 770px; // メインコンテンツインナー幅 - 770px(2つ内側のガイド)
$maxhalf: 600px; // 最大幅の半分 - 620px
$contenthalf: 500px; // メインコンテンツ幅の半分 - 500px
$tb: 480px; // タブレット - 480
$sp: 375px; // スマホ - 375

@mixin max-breakpoint-pc {
  @media all and (max-width: calc(#{$pc} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-tb {
  @media all and (max-width: calc(#{$tb} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-sp {
  @media all and (max-width: calc(#{$sp} - 1px)) {
    @content;
  }
}

@mixin breakpoint-max {
  @media all and (min-width: ($max)) {
    @content;
  }
}
@mixin breakpoint-mainbox {
  @media all and (min-width: ($mainbox)) {
    @content;
  }
}
@mixin breakpoint-content {
  @media all and (min-width: ($content)) {
    @content;
  }
}
@mixin breakpoint-pc {
  @media all and (min-width: ($pc)) {
    @content;
  }
}
@mixin breakpoint-contentinner {
  @media all and (min-width: ($contentinner)) {
    @content;
  }
}
@mixin breakpoint-maxhalf {
  @media all and (min-width: ($maxhalf)) {
    @content;
  }
}
@mixin breakpoint-contenthalf {
  @media all and (min-width: ($contenthalf)) {
    @content;
  }
}
@mixin breakpoint-tb {
  @media all and (min-width: ($tb)) {
    @content;
  }
}
@mixin breakpoint-sp {
  @media all and (min-width: ($sp)) {
    @content;
  }
}


////// display switch //////
.disp {
  &-block {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  
    &-sptb {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: block;
      }
    }
  
    &-sp {
      display: block;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: block;
      }
    }
  
    &-sponly {
      display: block;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }

  &-inline {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: inline;
      }
    }
  
    &-sptb {
      display: inline;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: inline;
      }
    }
  
    &-sp {
      display: inline;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: inline;
      }
    }
  
    &-sponly {
      display: inline;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }
}