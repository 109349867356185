.p3-img {
  // メニュー写真
  &.-i01 {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
  
  // インスタグラムアイコン(フィードの上のアイコン)
  &.-ig {
    text-align: center;

    a {
      display: inline-block;
    }

    img {
      width: 72px;
      height: auto;

      @include breakpoint-pc {
        width: 94px;
      }
    }
  }
  
  // メニュー名の左のアイコン
  &.-iconmenu {
    width: 44px;  //56 * 0.8
    height: auto;
    margin-right: 15px;
    
    @include breakpoint-pc {
      width: 56px;
      margin-right: 30px;
    }
  }
}